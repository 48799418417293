
import { defineComponent, ref } from 'vue'
import { NButton, NModal } from 'naive-ui'

export default defineComponent({
  components: {
    NButton,
    NModal,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const showModal = ref(false)

    return {
      showModal,
      historic: props.file,
    }
  },
})
