
import { defineComponent, ref } from 'vue'
import {
  useMessage,
  NButton,
  NModal,
  NForm,
  NFormItem,
  NInput,
  NDatePicker,
  NSelect,
  NSwitch,
  NIcon,
} from 'naive-ui'
import { API } from '@/api/api'
import { dateToDBDate } from '@/plugins/Date'
import { CreateOutline } from '@vicons/ionicons5'

export default defineComponent({
  components: {
    NButton,
    NModal,
    NForm,
    NFormItem,
    NSwitch,
    NInput,
    NDatePicker,
    NSelect,
    NIcon,
    CreateOutline,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    subgrupos: {
      type: Array,
      required: true,
    },
    emitUpdate: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const message = useMessage()
    const showModal = ref(false)
    const model = ref({
      titulo: null,
      data_publicacao: null as any,
      descricao: null,
      id_subgrupo: null,
      status: true,
    })

    const subgruposOptions = props.subgrupos.map((subgrupo: any) => ({
      label: subgrupo.titulo,
      value: subgrupo.id,
    }))

    async function updateFile() {
      try {
        const data = {
          ...model.value,
          data_publicacao: dateToDBDate(model.value.data_publicacao),
        }

        const response = await API().put(
          `/dashboard/arquivos/${props.file.id}`,
          data
        )

        if (response.status === 200) {
          message.success('Arquivo atualizado com sucesso!')
          props.emitUpdate()
          showModal.value = false
        } else {
          message.error(
            'Erro ao atualizar arquivo, verifique se os dados estão corretos e tente novamente mais tarde'
          )
        }
      } catch (error) {
        console.error(error)
        message.error(
          'Erro interno - Tente novamente mais tarde e contate o suporte.'
        )
      }
    }

    function openModal() {
      showModal.value = true
      model.value = {
        titulo: props.file.titulo,
        descricao: props.file.descricao,
        id_subgrupo: props.file.id_subgrupo,
        status: props.file.status == 1 ? true : false,
        data_publicacao: props.file.data_publicacao_raw
          ? new Date(props.file.data_publicacao_raw).getTime()
          : null,
      }
    }

    return {
      model,
      subgruposOptions,
      showModal,
      updateFile,
      openModal,
    }
  },
})
