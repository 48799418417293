import format from 'date-fns/format'
import { enUS } from 'date-fns/locale'
import ptBR from 'date-fns/locale/pt-BR'
import parse from 'date-fns/parse'

export function dateToLocalDate(date: string | Date) {
  if (typeof date === 'string') {
    date = parse(date, 'yyyy-MM-dd', new Date())
  }
  return format(date, 'dd/MM/yyyy', {
    locale: ptBR,
  })
}

export function dateToDBDate(date: string | Date) {
  if (typeof date === 'string') {
    date = parse(date, 'yyyy-MM-dd', new Date())
  }
  return format(date, 'yyyy-MM-dd', {
    locale: enUS,
  })
}

export function dateToDBYear(date: string | Date | null) {
  if (date === null) {
    return null
  }
  if (typeof date === 'string') {
    date = parse(date, 'yyyy', new Date())
  }
  return format(date, 'yyyy', {
    locale: enUS,
  })
}
