
import { defineComponent, onMounted, ref, h } from 'vue'
import { SearchOutline } from '@vicons/ionicons5'
import {
  NDataTable,
  NInput,
  NSelect,
  NDatePicker,
  NButton,
  NAlert,
  NSpin,
  NTag,
  useMessage,
  NBlockquote,
} from 'naive-ui'
import { useFiles, useSubgrupos, useSites } from '@/store'
import { TableColumns } from 'naive-ui/lib/data-table/src/interface'
import ModalFileEdit from '@/views/home/modals/ModalFileEdit.vue'
import ModalHistoric from '@/views/home/modals/ModalHistoric.vue'
import { dateToDBYear } from '@/plugins/Date'

export default defineComponent({
  components: {
    NDataTable,
    NSelect,
    NDatePicker,
    NInput,
    NButton,
    SearchOutline,
    NAlert,
    NSpin,
    // NBlockquote,
  },
  setup() {
    const message = useMessage()
    const hasSearch = ref(false)
    const files = useFiles()
    const subgrupos = useSubgrupos()
    const sites = useSites()
    const formRef = ref({
      id_site: null as any,
      id_subgrupo: null as any,
      id_situacao: null as any,
      search: null,
      year: null as any,
    })
    const sitesOptions = ref([])
    const subgruposOptions = ref([])
    const situacoesOptions = ref([
      {
        value: '0',
        label: 'Inativo',
      },
      {
        value: '1',
        label: 'Ativo',
      },
      {
        value: '2',
        label: 'Com descrição',
      },
      {
        value: '3',
        label: 'Sem descrição',
      },
      {
        value: '4',
        label: 'Sem grupo',
      },
    ])

    function getColumns() {
      return ref<TableColumns<any>>([
        {
          title: 'Documento',
          key: 'titulo',
        },
        {
          title: 'Grupo',
          key: 'grupo_titulo',
        },
        {
          title: 'Subgrupo',
          key: 'subgrupo_titulo',
        },
        {
          title: 'Data',
          key: 'data_publicacao',
        },
        {
          title: 'Descrição',
          key: 'descricao',
          width: '30%',
          render: (row: any) => {
            return h(
              NBlockquote,
              {
                class: 'text-sm',
                props: {
                  type: 'info',
                },
              },
              row.descricao != null && row.descricao.length > 0
                ? row.descricao.toString().toUpperCase()
                : '-'
            )
          },
        },
        {
          title: 'Situação',
          key: 'situacao',
          render: (row: any) => {
            if (row.status === 0) {
              return h(NTag, { type: 'error' }, 'Desativado')
            } else if (row.descricao == null || row.descricao == '') {
              return h(NTag, { type: 'warning' }, 'Sem descrição')
            } else if (row.descricao != null && row.descricao != '') {
              return h(NTag, { type: 'success' }, 'Com descrição')
            }
          },
        },
        {
          title: 'Ações',
          key: 'acoes',
          align: 'right',
          render: (row) => {
            return h('div', { class: 'space-x-2 justify-end' }, [
              h(ModalFileEdit, {
                file: row,
                subgrupos: subgrupos.data,
                emitUpdate: () => {
                  files.fetch()
                },
              }),
              h(ModalHistoric, {
                file: row,
                site: sites.data.find(
                  (x: any) => x.id == formRef.value.id_site
                ),
              }),
            ])
          },
        },
      ])
    }

    async function search() {
      if (formRef.value.id_site == null) {
        message.warning('Selecione um site...')
        return
      }
      hasSearch.value = false
      files.resetPagination()
      files.params = `&id_site=${formRef.value.id_site}&id_subgrupo=${
        formRef.value.id_subgrupo
      }&id_situacao=${formRef.value.id_situacao}&search=${
        formRef.value.search
      }&year=${dateToDBYear(formRef.value.year)}`
      await files.fetch()
      hasSearch.value = true
    }

    onMounted(async () => {
      await Promise.all([subgrupos.fetch(true), sites.fetch(true)])
      files.subgrupos = subgrupos.data
      sitesOptions.value = sites.data.map((site: any) => ({
        label: `${site.id} - ${site.nome}`,
        value: site.id,
      }))
      subgruposOptions.value = subgrupos.data.map((subgrupo: any) => ({
        label: subgrupo.titulo,
        value: subgrupo.id,
      }))
    })

    return {
      files,
      columns: getColumns(),
      formRef,
      sitesOptions,
      subgruposOptions,
      situacoesOptions,
      hasSearch,
      search,
    }
  },
})
